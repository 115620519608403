import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)


const gameRoutes = [
  {
    path: '/',
    component: () => import('@/components/playGame'),
    meta: {
      title: '游戏',
    }
  },
  {
    path: '/index',
    name: "index",
    component: () => import('@/components/index'),// 首页
    meta: {
      title: '首页',
    }
  },
]



const indexRoutes = [
  {
    path: '/',
    component: () => import('@/components/index'),// 首页
    meta: {
      title: '首页',
    }
  }, {
    path: '/playGame',
    name: "playGame",
    component: () => import('@/components/playGame'),
    meta: {
      title: '游戏',
    }
  },
]



export default new VueRouter({
  // mode:"history",
  routes: window.matchMedia('(display-mode: standalone)').matches ?gameRoutes :  indexRoutes

})
