import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import Vant from 'vant';
import 'vant/lib/index.css';
// import VueFacebookPixel from 'vue-facebook-pixel'

// Vue.use(VueFacebookPixel)



const obj = { deferredprompt: null }

window.addEventListener('beforeinstallprompt', (event) => {
  console.log('Before install prompt fired66666666');

  obj.deferredprompt = event;
  event.preventDefault();

  console.log(obj.deferredprompt);

  Vue.prototype.$deferredPrompt = obj;

});












import router from './router'

Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
